import React, { useContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Typography, Table, TableHead, TableBody, TableRow, TableCell, TableContainer } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useNodeList } from 'src/kiska/components/NodeList';

const useStyles = makeStyles((theme) => ({
  root: {

  },
  table: {
    '& td': {
      fontSize: '1.0rem',
    },
  },
  totalHoursCell: {
    fontSize: '1.2rem !important',
  },
  totalsRow: {
    '& td': {
      fontWeight: 700,
      color: theme.palette.text.secondary,
      fontSize: '1.2rem',
      borderBottom: 'none',
    },
  },
}));

const PayrollWorkEntriesTable = (props) => {
  const classes = useStyles(props);
  const { nodes } = useNodeList();

  const totals = useMemo(() => {
    return {
      days: (nodes.reduce((acc, node) => acc + node.dayCount, 0)),
      hours: (nodes.reduce((acc, node) => acc + node.sumDuration, 0) / 60 / 60).toFixed(1),
      avgHoursPerDay: (nodes.reduce((acc, node) => acc + node.sumDuration / node.dayCount, 0) / nodes.length / 60 / 60).toFixed(1),
    };
  }, [nodes]);

  return (
    <TableContainer>
      <Table className={classes.table}>
        <TableHead className={classes.head}>
          <TableRow>
            <TableCell>Worker</TableCell>
            <TableCell>Pay Rate</TableCell>
            <TableCell align="right">Days Worked</TableCell>
            <TableCell align="right">Avg. Hours/Day</TableCell>
            <TableCell align="right">Total Hours</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {nodes.map((node) => {
            return (
              <TableRow key={node.id} hover>
                <TableCell>{node.user.displayName}</TableCell>
                <TableCell>{node.payRate.label}</TableCell>
                <TableCell align="right">{node.dayCount}</TableCell>
                <TableCell align="right">{(node.sumDuration / 60 / 60 / node.dayCount).toFixed(1)}</TableCell>
                <TableCell align="right" className={classes.totalHoursCell}>{(node.sumDuration / 60 / 60).toFixed(1)}</TableCell>
              </TableRow>
            );
          })}
          <TableRow className={classes.totalsRow} hover>
            <TableCell />
            <TableCell />
            <TableCell align="right">Totals:</TableCell>
            <TableCell align="right">{totals.avgHoursPerDay}</TableCell>
            <TableCell align="right">{totals.hours}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

PayrollWorkEntriesTable.propTypes = {
};
PayrollWorkEntriesTable.defaultProps = {
};

export { PayrollWorkEntriesTable };
