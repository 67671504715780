import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Typography, Grid, Button, useMediaQuery } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import PayrollIcon from 'mdi-material-ui/AccountCash';
import CustomerIcon from 'mdi-material-ui/AccountTie';
import {
  Build as JobIcon,
} from '@material-ui/icons';
import { usePageMeta } from 'src/kiska/components/contexts/PageMetaContext';
import WorkerIcon from 'mdi-material-ui/AccountHardHat';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2, 1),
    alignItems: 'center !important',
  },
  button: {
    padding: theme.spacing(4, 2),
    width: '100%',
    '& > span': {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    },
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(1, 2),
    },
  },
  icon: {
    fontSize: 50,
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      fontSize: 30,
      marginBottom: theme.spacing(1),
    },
  },
}));

const ReportButton = (props) => {
  const { primary, secondary, icon, to } = props;
  const Icon = icon;
  const classes = useStyles(props);

  return (
    <Button component={Link} to={to} variant="contained" color="primary" className={classes.button}>
      <Icon className={classes.icon} />
      <Typography variant="h5" style={{ letterSpacing: '.1em' }}>{primary}</Typography>
    </Button>
  );
};

const Reports = (props) => {
  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.down('sm'), { noSsr: true });
  const classes = useStyles(props);
  const { setTitles } = usePageMeta();

  useEffect(() => {
    setTitles('Reports');
  }, [setTitles]);

  return (
    <div className={classes.root}>
      <Grid container spacing={sm ? 2 : 6} justify="center" alignItems="center">
        <Grid item xs={12} sm={6} md={6}>
          <ReportButton to="/app/reports/payroll" primary="Payroll" icon={PayrollIcon} />
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <ReportButton to="/app/reports/users" primary="Worker" icon={WorkerIcon} />
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <ReportButton to="/app/reports/jobs" primary="Job" icon={JobIcon} />
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <ReportButton to="/app/reports/customers" primary="Customer" icon={CustomerIcon} />
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <ReportButton to="/app/reports/job-user" primary="Jobs x Worker" icon={WorkerIcon} />
        </Grid>
      </Grid>
    </div>
  );
};

Reports.propTypes = {
};
Reports.defaultProps = {
};

export { Reports };
