import React from 'react';
// import PropTypes from 'prop-types';
// import { Typography } from '@material-ui/core';
// import { makeStyles } from '@material-ui/core/styles';
import { BrowserRouter, Switch, Redirect } from 'react-router-dom';
import HomePage from './components/HomePage';
import AuthenticatedRoute from './components/layouts/AuthenticatedRoute';
import { DashboardRouter } from './components/dashboards/DashboardRouter';
import { JobSwitcher } from './components/modules/job/JobSwitcher';
import Customers from './components/dashboards/Customers';
import Users from './components/dashboards/Users';
import Jobs from './components/dashboards/Jobs';
import { Forms } from './components/dashboards/Forms';
import { WorkerBoard } from './components/modules/user/WorkerBoard';
import { UserUpdateForm } from './components/modules/user/UserUpdateForm';
import UserPageView from './components/modules/user/UserPageView';
import JobPageView from './components/modules/job/JobPageView';
import { JobUpdateForm } from './components/modules/job/JobUpdateForm';
import { FormPageView } from './components/modules/form/FormPageView';
import { FormUpdateForm } from './components/modules/form/FormUpdateForm';
import CustomerPageView from './components/modules/customer/CustomerPageView';
import { CustomerUpdateForm } from './components/modules/customer/CustomerUpdateForm';
import { ToolsGrid } from './components/modules/tools/ToolsGrid';
import { PressBrakeTool } from './components/modules/tools/PressBrake';
import { SettingsDashboard } from './components/dashboards/settings/SettingsDashboard';
import { AnonLayout } from './components/layouts/AnonLayout';
import { Reports } from './components/dashboards/Reports';
import { PayrollReport } from './components/dashboards/PayrollReport';
import { BlockNavigationProvider } from './kiska/components/contexts/BlockNavigationContext';
import { JobReports } from './components/dashboards/JobReports';
import { CustomerReports } from './components/dashboards/CustomerReports';
import { UserReports } from './components/dashboards/UserReports';
import { MyWorkLog } from './components/MyWorkLog';
import { SupportPage } from './components/SupportPage/SupportPage';
import { JobBoard } from './components/dashboards/JobBoard';
import { CalendarPage } from './components/dashboards/CalendarPage';
import { MyCalendar } from './components/dashboards/MyCalendar';
import { Resources } from './components/modules/resource/Resources';
import { JobUserReport } from './components/dashboards/JobUserReport';

// const useStyles = makeStyles((theme) => ({
//   root: {
//   },
// }));

const Router = (props) => {
  // const classes = useStyles(props);
  const { children } = props;

  return (
    <BrowserRouter>
      <BlockNavigationProvider>
        {children}
        <Switch>
          {/* Dashboards */}
          <AuthenticatedRoute path="/app/dashboard" component={DashboardRouter} />
          <AuthenticatedRoute path="/app/job-switcher" component={JobSwitcher} />
          <AuthenticatedRoute path="/app/worker-board" component={WorkerBoard} />
          <AuthenticatedRoute path="/app/calendar" component={CalendarPage} />
          <AuthenticatedRoute path="/app/my-calendar" component={MyCalendar} />

          {/* Resources */}
          <AuthenticatedRoute path="/app/resources" component={Resources} />

          {/* Users */}
          <AuthenticatedRoute path="/app/my-work-log" component={MyWorkLog} />
          <AuthenticatedRoute path="/app/users/:id/edit" component={UserUpdateForm} />
          <AuthenticatedRoute path="/app/users/:id" component={UserPageView} />
          <AuthenticatedRoute path="/app/profile/:id" component={UserPageView} />
          <AuthenticatedRoute path="/app/users" component={Users} />

          {/* Jobs */}
          <AuthenticatedRoute path="/app/jobs/:id/edit" component={JobUpdateForm} />
          <AuthenticatedRoute path="/app/jobs/:id" component={JobPageView} />
          <AuthenticatedRoute path="/app/jobs" component={Jobs} />
          <AuthenticatedRoute path="/app/job-board" component={JobBoard} layoutProps={{ hideFooter: true }} />

          {/* Forms */}
          <AuthenticatedRoute path="/app/forms/:id/edit" component={FormUpdateForm} />
          <AuthenticatedRoute path="/app/forms/:id" component={FormPageView} />
          <AuthenticatedRoute path="/app/forms" component={Forms} />

          {/* Customers */}
          <AuthenticatedRoute path="/app/customers/:id/edit" component={CustomerUpdateForm} />
          <AuthenticatedRoute path="/app/customers/:id" component={CustomerPageView} />
          <AuthenticatedRoute path="/app/customers" component={Customers} />

          {/* Reports */}
          <AuthenticatedRoute path="/app/reports/payroll" component={PayrollReport} />
          <AuthenticatedRoute path="/app/reports/jobs" component={JobReports} />
          <AuthenticatedRoute path="/app/reports/customers" component={CustomerReports} />
          <AuthenticatedRoute path="/app/reports/job-user" component={JobUserReport} />
          <AuthenticatedRoute path="/app/reports/users" component={UserReports} />
          <AuthenticatedRoute path="/app/reports" component={Reports} />

          {/* Tools */}
          <AuthenticatedRoute path="/app/online-tools" component={ToolsGrid} />
          <AuthenticatedRoute path="/app/online-tools/press-brake-bends" component={PressBrakeTool} />

          {/* Settings */}
          <AuthenticatedRoute path="/app/settings" component={SettingsDashboard} />

          {/* Dashboard/App Homepage */}
          <AuthenticatedRoute path="/app" component={DashboardRouter} />

          {/* Public homepage */}
          <AuthenticatedRoute path="/" exact isPublic component={HomePage} layout={AnonLayout} />

          {/* Others */}
          <AuthenticatedRoute path="/support" isPublic component={SupportPage} />

          {/* Catch All */}
          <Redirect from="*" to="/" />
        </Switch>
      </BlockNavigationProvider>
    </BrowserRouter>
  );
};

Router.propTypes = {
};
Router.defaultProps = {
};

export { Router };
