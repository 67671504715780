import React, { useContext, useState } from 'react';
// import PropTypes from 'prop-types';
import { Typography, useMediaQuery, ButtonGroup, Button, ListItemIcon, Chip, Paper, Menu, MenuItem, ListItemText } from '@material-ui/core';
import { Grid } from 'src/kiska/components/Grid';
import { Create as EditIcon, Dashboard as JobPageIcon, AttachFile as FilesIcon } from '@material-ui/icons';
import { green } from '@material-ui/core/colors';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Link } from 'src/kiska/components/Link';
import { Link as RouterLink } from 'react-router-dom';
import ExpensesIcon from 'mdi-material-ui/CashRegister';
import MenuDotsIcon from 'mdi-material-ui/DotsVertical';
import DurationTicker from 'src/components/modules/user/DurationTicker';
import TextField from 'src/kiska/components/MutationContext/FormFields/TextField';
import CrudForm from 'src/kiska/components/MutationContext/CrudForm';
import { SettingsContext } from 'src/kiska/components/contexts/SettingsContext';
import { FilePreviewsContainer } from 'src/kiska/components/MutationContext/FormFields/FileField/FilePreviewsContainer';
import { FormSection } from 'src/components/forms';
import { AddFilesButtons } from 'src/components/AddFilesButtons';
import { SimpleTable } from 'src/components/dashboards/settings/SimpleTable';
import { usePopupState, bindTrigger, bindMenu } from 'material-ui-popup-state/hooks';
import ChangeOrderIcon from 'mdi-material-ui/ArrowDecision';
import { useAppSettings } from 'src/kiska/components/contexts/AppSettingsContext';
import ResourceIcon from 'mdi-material-ui/Bookshelf';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import SlopeIcon from 'mdi-material-ui/SlopeUphill';
import { JobUpdateForm } from '../JobUpdateForm';
import { useWorkEntryControls } from '../../work_entry/useWorkEntryControls';
import { StartJobButton } from './StartJobButton';
import { StopWorkButton } from './StopWorkButton';
import { UpdateJobStatusButton } from '../UpdateJobStatusButton';
import { ExpenseQuickAdd } from '../../expense/ExpenseQuickAdd';
import { useExpenseTableColumns } from '../../expense/useExpenseTableColumns';
import { ExpenseUpdateForm } from '../../expense/ExpenseUpdateForm';
import { ContactRenderer } from '../../customer/ContactRenderer';
import { usePrevDailySafetyFormValues } from '../../form/forms/usePrevDailySafetyFormValues';
import { DailySafetyFormButton } from '../../form/forms/DailySafetyFormButton';
import { AddChangeOrderButton } from '../../form/forms/AddChangeOrderButton';
import { forms } from '../../form/forms';
import { FormViewDialog } from '../../form/FormViewDialog';
import { useChangeOrderColumns } from '../../form/forms/useChangeOrdersColumns';
import { useResourceTableColumns } from '../../resource/useResourceTableColumns';
import { ResourceUpdateForm } from '../../resource/ResourceUpdateForm';
import { ResourceViewDialog } from '../../resource/ResourceViewDialog';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(0, 2, 2, 2),
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  openWorkEntry: {
    width: '100%',
    marginBottom: theme.spacing(4),
  },
  switchJobsColumn: {
    margin: theme.spacing(0, 0, 0, 0),
    backgroundColor: theme.palette.background.secondary,
  },
  switchJobsButton: {
    margin: theme.spacing(1, 0),
  },
  alert: {
    alignItems: 'center',
    padding: theme.spacing(1, 1),
  },
  formSection: {
  },
  formSectionContent: {
    padding: theme.spacing(2, 2, 2, 2),
  },
  customer: {
    padding: theme.spacing(1),
  },
}));

const OpenWorkEntry = (props) => {
  const { appSettings } = useAppSettings();
  const { node } = props;
  const classes = useStyles(props);
  const theme = useTheme();
  const lg = useMediaQuery(theme.breakpoints.up('lg'), { noSsr: true });
  const job = node.job;
  const { required: daiySafetyFormRequired } = usePrevDailySafetyFormValues({ job });
  const moreMenuState = usePopupState({ variant: 'popover' });
  const { columns: expensesColumns } = useExpenseTableColumns({
    displayParams: {
      showPrice: false,
      showCost: true,
    },
  });
  const { columns: resourcesColumns } = useResourceTableColumns({ showTags: true });
  const { columns: changeOrderColumns } = useChangeOrderColumns({});

  return (
    <div key={node.id} className={classes.openWorkEntry}>
      <Grid container spacing={2} justify="flex-start">
        {/* Ticker */}
        <Grid item xs={12} lg={12} container direction="column" justify="center" alignItems="center" style={{ width: 'auto' }}>
          {/* Safari Bug, needs flex 1 */}
          <Link to={`/app/jobs/${job.id}`} style={{ width: 'auto' }}>
            <Typography variant="h6" align="center">{job.shortId} - {job.title}</Typography>
          </Link>

          {/* Safari Bug, needs flex 1 */}
          <Grid item xs={12} lg={12} container justify="center" alignItems="center" style={{ width: 'auto', flex: 1 }} direction="row">
            <Typography variant="body1" color="textSecondary">
              <Chip component="span" label={`${node.task.label}`} size="small" />
            </Typography>
            <Typography component="span" variant="h6" style={{ color: green[500], marginLeft: 8 }}>
              <DurationTicker start={node.start} end={node.end} includeSeconds />
            </Typography>
          </Grid>
        </Grid>

        {/* Customer */}
        <Grid item xs={12} lg={12}>
          <Paper className={classes.customer}>
            <ContactRenderer contact={job.customer} addressProps={{ color: 'textSecondary' }} />
          </Paper>
        </Grid>

        {/* Daily Safety */}

        {/* Buttons */}
        <Grid item container alignItems="center" spacing={2}>
          {appSettings.data.jobs.useDailySafetyRecords && daiySafetyFormRequired && (
            <Grid item style={{ textAlign: 'center' }}>
              <DailySafetyFormButton job={job} variant="contained" size="small" color="primary" />
            </Grid>
          )}
          <Grid item>
            <ButtonGroup size="small" color="primary" variant="contained">
              <AddFilesButtons node={job} type="job" onlyCamera hideLabelBreakpoint={null} />
              {/* <Button component={Link} to={`/app/jobs/${job.id}`}><JobPageIcon /><span>Job Details</span></Button> */}
              <ExpenseQuickAdd job={job} onlyCamera cameraLabel="Add Expense" cameraIcon={ExpensesIcon} />
              <Button {...bindTrigger(moreMenuState)}><MenuDotsIcon /><span>More</span></Button>
            </ButtonGroup>
            <Menu {...bindMenu(moreMenuState)} autoFocus={false} disableAutoFocusItem={true}>
              <MenuItem component={RouterLink} to={`/app/jobs/${job.id}/details`}>
                <ListItemIcon><JobPageIcon /></ListItemIcon><ListItemText primary="View Job Details" />
              </MenuItem>
              <JobUpdateForm
                onClose={moreMenuState.close}
                id={job.id}
                trigger={(
                  <MenuItem>
                    <ListItemIcon><EditIcon /></ListItemIcon><ListItemText primary="Edit Job" />
                  </MenuItem>
                )}
              />
              {appSettings.data.jobs.useChangeOrders && (
                <AddChangeOrderButton
                  onClose={moreMenuState.close}
                  job={job}
                  trigger={(
                    <MenuItem>
                      <ListItemIcon><ChangeOrderIcon /></ListItemIcon><ListItemText primary="Create Change Order" />
                    </MenuItem>
                  )}
                />
              )}
            </Menu>
          </Grid>
        </Grid>

        {/* Edit Notes */}
        <Grid item xs={12} />
        <Grid item xs={12}>
          <CrudForm type="work_entry" node={node} onChangeMutation="update">
            <TextField
              name="notes"
              variant="outlined"
              label="Notes on your current log entry"
              multiline
            />
          </CrudForm>
        </Grid>

        <Grid item xs={12}>
          {appSettings.data.jobs.useResources && (
            <FormSection
              title={`Resources (${job.resources_aggregate.aggregate.count})`}
              icon={ResourceIcon}
              initiallyExpanded={false}
              classes={{ root: classes.formSection, content: classes.formSectionContent }}
            >
              {/* <Grid item xs={12}> */}
              <SimpleTable
                type="resource"
                addLabel="Add New Resource"
                columns={resourcesColumns}
                fixtures={[
                  { path: 'where.jobs.job.id._eq', value: job.id },
                ]}
                actionsOnBottom={false}
                actionsOnTop={false}
                floatingAdd
                enableAdd={false}
                enableEdit={false}
                showSearchBox={false}
                rowClickAction="view"
                ViewDialog={ResourceViewDialog}
                viewDialogProps={{ updateFormProps: { fixtures: {}, showExpires: false } }}
              />
              {/* </Grid> */}
            </FormSection>
          )}

          {!!job.files.length && (
            <FormSection
              title={`Job Files (${job.files.length})`}
              highlighted={true}
              initiallyExpanded={lg}
              classes={{ root: classes.formSection, content: classes.formSectionContent }}
              icon={FilesIcon}
            >
              <FilePreviewsContainer enableView files={job.files.map((file) => file.file)} className={classes.previews} />
            </FormSection>
          )}

          {/* <FormSection
            title="Steep Slope Risk Assessment"
            initiallyExpanded={true}
            classes={{ root: classes.formSection, content: classes.formSectionContent }}
            icon={SlopeIcon}
          >
            <SimpleTable
              type="form"
              columns={['data.owner', 'data.block', 'data.site']}
              UpdateForm={forms.SteepSlopeLoggingRiskAssessmentSitePreWork_v1.UpdateForm}
              updateFixtures={{ jobs: [{ id: job.id }] }}
              insertFixtures={{ jobs: [{ id: job.id }] }}
              fixtures={[
                { path: 'where.jobs.job.id._eq', value: job.id },
                { path: 'where.name._eq', value: 'SteepSlopeLoggingRiskAssessmentSitePreWork' },
              ]}
              rowClickAction="edit"
              ViewDialog={FormViewDialog}
              viewDialogProps={{}}
            />
          </FormSection> */}

          {!!job.expenses_aggregate.aggregate.count && (
            <FormSection
              title={`Expenses (${job.expenses_aggregate.aggregate.count})`}
              highlighted={true}
              initiallyExpanded={false}
              classes={{ root: classes.formSection, content: classes.formSectionContent }}
              icon={ExpensesIcon}
            >
              <SimpleTable
                type="expense"
                columns={expensesColumns}
                UpdateForm={ExpenseUpdateForm}
                updateFixtures={{ job }}
                insertFixtures={{ job }}
                fixtures={[{ path: 'where.jobId._eq', value: job.id }]}
                rowClickAction="edit"
              />
            </FormSection>
          )}

          {!!job.change_orders_aggregate.aggregate.count && (
            <FormSection
              title={`Change Orders (${job.change_orders_aggregate.aggregate.count})`}
              highlighted={true}
              initiallyExpanded={false}
              classes={{ root: classes.formSection, content: classes.formSectionContent }}
              icon={ChangeOrderIcon}
            >
              <SimpleTable
                type="form"
                columns={changeOrderColumns}
                UpdateForm={forms.ChangeOrderForm_v1.UpdateForm}
                updateFixtures={{ jobs: [{ id: job.id }] }}
                insertFixtures={{ jobs: [{ id: job.id }] }}
                fixtures={[
                  { path: 'where.jobs.job.id._eq', value: job.id },
                  { path: 'where.name._eq', value: 'ChangeOrderForm' },
                ]}
                rowClickAction="view"
                ViewDialog={FormViewDialog}
                viewDialogProps={{}}
              />
            </FormSection>
          )}
        </Grid>

        <Grid item>
          <UpdateJobStatusButton node={job} size="small" color="primary" variant="contained" labelPrefix="Job Stage:" />
        </Grid>
      </Grid>
    </div>
  );
};

const jobHasTagInFilter = (tagIds) => (job) => {
  if (!tagIds || !tagIds.length) return true;
  return (job.tags || []).find(({ tag }) => tagIds.includes(tag.id));
};

const JobSwitcher = () => {
  const [tagIds, setTagIds] = useState([]);
  const where = {};
  if (tagIds.length) {
    where.tags = { tag: { id: { _in: tagIds } } };
  }
  const {
    availableJobs: unfilteredAvailableJobs,
    openWorkEntries,
    currentlyWorking,
    assignedJobNodes: unfilteredAssignedJobNodes,
    unassignedJobNodes: unfilteredUnassignedJobNodes,
    scheduledWorkEvents,
    unscheduledAvailableJobs: unfilteredUnscheduledAvailableJobs,
  } = useWorkEntryControls({
    where,
  });
  const settings = useContext(SettingsContext);
  const classes = useStyles();
  const { appSettings } = useAppSettings();

  const handleTagIdsChange = (event, value) => {
    setTagIds(value || []);
  };

  const availableJobs = unfilteredAvailableJobs.filter(jobHasTagInFilter(tagIds));
  const assignedJobNodes = unfilteredAssignedJobNodes.filter(jobHasTagInFilter(tagIds));
  const unassignedJobNodes = unfilteredUnassignedJobNodes.filter(jobHasTagInFilter(tagIds));
  const unscheduledAvailableJobs = unfilteredUnscheduledAvailableJobs.filter(jobHasTagInFilter(tagIds));

  const renderAvailableJobs = () => {
    if (!availableJobs.length) {
      return (
        <Grid item xs={12}>
          <Typography variant="body1" align="center" gutterBottom>
            No {currentlyWorking ? 'other' : ''} jobs to work on right now.<br /><br /><br />
          </Typography>
        </Grid>
      );
    }

    return (
      <>
        {appSettings.data.jobs.useScheduling && (
          <>
            <Grid item xs={12}>
              <Typography variant="h6" color="textSecondary">
                Scheduled For Today
              </Typography>
            </Grid>
            {scheduledWorkEvents.length
              ? scheduledWorkEvents.map((event) => {
                return (
                  <StartJobButton key={event.id} job={event.job} size="big" event={event} className={classes.switchJobsButton} />
                );
              })
              : <Typography variant="body2" color="textSecondary" style={{ width: '100%', marginLeft: 8 }}>Nothing scheduled for today.</Typography>}
          </>
        )}
        {appSettings.data.jobs.useAssignments && (
          <>
            <Grid item xs={12}>
              <Typography variant="h6" color="textSecondary">
                Assigned to You
              </Typography>
            </Grid>
            {assignedJobNodes.length
              ? assignedJobNodes.map((job) => (
                <StartJobButton key={job.id} job={job} size="big" className={classes.switchJobsButton} />
              ))
              : <Typography variant="body2" color="textSecondary" style={{ width: '100%', marginLeft: 8 }}>No jobs assigned to you.</Typography>}
            <Grid item xs={12}>
              <Typography variant="h6" color="textSecondary">
                Unassigned Jobs
              </Typography>
            </Grid>
            {unassignedJobNodes.map((job) => (
              <StartJobButton key={job.id} job={job} size="big" className={classes.switchJobsButton} />
            ))}
          </>
        )}
        {!appSettings.data.jobs.useAssignments && !!unscheduledAvailableJobs.length && (
          <>
            <Grid item xs={12}>
              <Typography variant="h6" color="textSecondary">
                Active Jobs
              </Typography>
            </Grid>
            {unscheduledAvailableJobs.map((job) => (
              <StartJobButton key={job.id} job={job} size="big" className={classes.switchJobsButton} />
            ))}
          </>
        )}
      </>
    );
  };

  return (
    <div className={classes.root}>

      <Grid container spacing={4} justify="center" alignItems="stretch" style={{ flex: 1 }}>

        {/* <Grid item xs={12}>
          <Alert
            elevation={3}
            severity="warning"
            className={classes.alert}
          // icon={<NotificationIcon fontSize="inherit" />}
          >
            <Typography variant="body1">
              Note: We are currently updating the software.
              Some jobs may not appear in this list until they are assigned to the electricians.
            </Typography>
          </Alert>
        </Grid> */}

        {currentlyWorking && (
          <Grid item xs={12} lg={6} style={{ paddingRight: 16 }}>
            {openWorkEntries.map((entry) => <OpenWorkEntry key={entry.id} node={entry} />)}
          </Grid>
        )}

        <Grid item container justify="center" alignContent="flex-start" spacing={2} lg={currentlyWorking ? 6 : 12} className={classes.switchJobsColumn}>
          <Grid item xs={12}>
            <Typography variant="h4" align="center" color="inherit">
              {currentlyWorking ? 'Switch Jobs' : 'Start Working'}
            </Typography>
          </Grid>

          {appSettings.data.jobs.enableJobSwitcherTags && (
            <Grid item xs={12}>
              <ToggleButtonGroup value={tagIds} exclusive onChange={handleTagIdsChange} size="medium">
                {/* <ToggleButton value="client-job">Client Jobs</ToggleButton> */}
                <ToggleButton value="maintenance">Non-Client Work</ToggleButton>
                <ToggleButton value="break">Break</ToggleButton>
              </ToggleButtonGroup>
            </Grid>
          )}

          <Grid xs={12} item container spacing={2} direction="row" justify="center" alignContent="flex-start" style={{ flex: 1 }}>
            {renderAvailableJobs()}
          </Grid>

          <Grid xs={12} item style={{ textAlign: 'center' }}>
            <StopWorkButton showLabel showTime={false} showJob={false} size="big" />
          </Grid>
        </Grid>

      </Grid>

    </div>
  );
};

JobSwitcher.propTypes = {
};
JobSwitcher.defaultProps = {
};

export { JobSwitcher };
