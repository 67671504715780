import React, { useContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Typography, Table, TableHead, TableBody, TableRow, TableCell, TableContainer } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useNodeList } from 'src/kiska/components/NodeList';

const useStyles = makeStyles((theme) => ({
  root: {

  },
  table: {
    '& td': {
      fontSize: '1.0rem',
    },
  },
  totalHoursCell: {
    fontSize: '1.2rem !important',
  },
  totalsRow: {
    '& td': {
      fontWeight: 700,
      color: theme.palette.text.secondary,
      fontSize: '1.2rem',
      borderBottom: 'none',
    },
  },
}));

const JobUserReportTable = (props) => {
  const classes = useStyles(props);
  const { nodes } = useNodeList();

  const totals = useMemo(() => {
    return {
      days: (nodes.reduce((acc, node) => acc + node.dayCount, 0)),
      hours: (nodes.reduce((acc, node) => acc + node.sumDuration, 0) / 60 / 60).toFixed(1),
      avgHoursPerDay: (nodes.reduce((acc, node) => acc + node.sumDuration / node.dayCount, 0) / nodes.length / 60 / 60).toFixed(1),
    };
  }, [nodes]);

  console.log('nodes', nodes);

  const jobs = {};
  const users = {};

  nodes.forEach((node) => {
    if (!jobs[node.job.id]) {
      jobs[node.job.id] = {
        job: node.job,
        users: {},
      };
    }
    if (!jobs[node.job.id].users[node.user.id]) {
      jobs[node.job.id].users[node.user.id] = {
        user: node.user,
        hours: 0,
        price: 0,
        cost: 0,
      };
    }
    jobs[node.job.id].users[node.user.id].hours += node.sumDuration / 60 / 60;
    jobs[node.job.id].users[node.user.id].price += node.sumPrice;
    jobs[node.job.id].users[node.user.id].cost += node.sumCost;

    if (!users[node.user.id]) {
      users[node.user.id] = {
        user: node.user,
        totalHours: 0,
      };
    }
    users[node.user.id].totalHours += node.sumDuration / 60 / 60;
  });

  return (
    <TableContainer>
      <Table className={classes.table}>
        <TableHead className={classes.head}>
          <TableRow>
            <TableCell>Job</TableCell>
            <TableCell>Work ORder</TableCell>
            {Object.values(users).map((u) => (
              <TableCell key={u.user.id}>{u.user.displayName}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {Object.values(jobs).map((job) => {
            return (
              <TableRow key={job.job.id} hover>
                <TableCell>{job.job.title}</TableCell>
                <TableCell>{job.job.shortId}</TableCell>
                {Object.values(users).map((u) => {
                  const user = job.users[u.user.id];
                  return (
                    <TableCell key={u.id} align="right">{user ? user.hours.toFixed(1) : 0}</TableCell>
                  );
                })}
              </TableRow>
            );
          })}
          <TableRow className={classes.totalsRow} hover>
            <TableCell />
            <TableCell align="right">Totals:</TableCell>
            {Object.values(users).map((u) => (
              <TableCell key={u.id} align="right">{u.totalHours.toFixed(1)}</TableCell>
            ))}
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

JobUserReportTable.propTypes = {
};
JobUserReportTable.defaultProps = {
};

export { JobUserReportTable };
